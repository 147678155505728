import styled from 'styled-components';
import { renderStyledElementStyles } from '../../helpers';
import { VideoGalleryLayoutAlignment } from './types';

export const Wrapper = styled.div`
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)};
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles[theme.module.style])}

  h1, h2 {
    ${({ theme }) =>
      renderStyledElementStyles(theme, theme.module.vStyles.elements.heading)}
  }

  p {
    ${({ theme }) =>
      renderStyledElementStyles(theme, theme.module.vStyles.elements.paragraph)}
  }

  a {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.callToAction,
      )}

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles[theme.module.style].elements?.callToAction,
      )}
  }

  p,
  li,
  li::marker,
  h1,
  h2 {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.textStyles[theme.module.textColour],
      )}
  }
`;

export const Line = styled.div`
  width: 100%;
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.line)}

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles[theme.module.style].elements?.line,
    )}
`;

export const VideoTitle = styled.div`
  padding: 16px 0 40px 0;
`;

export const Row = styled.div`
  justify-content: ${({ theme }) => {
    if (theme.module.layoutAlignment === VideoGalleryLayoutAlignment.LEFT)
      return 'left';
    if (theme.module.layoutAlignment === VideoGalleryLayoutAlignment.RIGHT)
      return 'right';
    return 'center';
  }};
`;

export const Col = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
`;

export const CTAWrapper = styled.div`
  text-align: center;
`;
