import React from 'react';
import { VideoGalleryEntrySection } from '../../types';
import { parseStreamlyUrl } from '../../../../../../utils/streamlyVideos';
import { VideoSource } from '../../../../../../types';
import { Col, VideoTitle } from '../../index.style';
import { Thumbnail } from '../../../sharedComponents';

export interface VideoCardProps extends VideoGalleryEntrySection {
  onClick: (video: VideoGalleryEntrySection) => void;
}

export function VideoCard(props: VideoCardProps) {
  const { videoUrl, posterImage, videoSource, videoId, videoTitle, onClick } =
    props;
  const { thumbnailUrl } = parseStreamlyUrl(videoUrl);

  const buildThumbnail = () => {
    if (posterImage) return posterImage.path;
    if (thumbnailUrl && videoSource === VideoSource.STREAMLY_VIDEO) {
      return thumbnailUrl;
    }

    return `https://img.youtube.com/vi/${videoId}/0.jpg`;
  };

  return (
    <Col className="col-xs-12 col-md-4" onClick={() => onClick(props)}>
      <Thumbnail
        path={buildThumbnail()}
        imgix={!!posterImage?.path}
        testId="video-thumbnail"
      />
      <VideoTitle>{videoTitle}</VideoTitle>
    </Col>
  );
}
